.video-group {
  position: relative;
  height: 100vh;

  .video-wrap {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    object-fit: cover;
    position: absolute;
    z-index: 1;
  }

  .screen-box {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0.3) 100%);
  }

  .content-group {
    width: 100%;
    position: absolute;
    bottom: 0;
    // padding-top: 80vh;
  }

  .slide-browse {
    width: 116px;
    height: 60px;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 2rem auto;

  }

  .slide-icon {
    font-size: 12px;
    color: #fff;
    transform: scale(0.8);
    text-align: center;
    cursor: pointer;
    animation: fade 2s infinite;
    animation-delay: -1s;
    display: block;
    margin-bottom: -5px;
  }

  .slide-icon1 {
    font-size: 12px;
    color: #fff;
    transform: scale(0.8);
    text-align: center;
    cursor: pointer;
    animation: fade 1s infinite;
  }

  .subtitle {
    opacity: 0.3;
    transition: opacity 1s ease-in;
  }

  .subtitle-fadein {
    opacity: 1;
  }

  .subtitle-fadeout {
    opacity: 0;
  }

  @keyframes fade {
    0% {
      color: rgba(255, 255, 255, 1);
    }

    30% {
      color: rgba(255, 255, 255, 0.8);
    }

    60% {
      color: rgba(255, 255, 255, 0.5);
    }

    80% {
      color: rgba(255, 255, 255, 0.8);
    }

    100% {
      color: rgba(255, 255, 255, 1);
    }
  }

  .content-txt {
    width: 80%;
    white-space: normal;
    text-align: center;
    color: rgba(255, 255, 255, 0.85);
    font-size: 1.34rem;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    line-height: 22px;
    margin: 0 auto;

  }

  .browse-more {
    height: 1.17rem;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 1.17rem;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 17px;
    margin-top: 8px;
  }

}

.swiper-btn {
  width: 40px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  content: none;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 56%) !important;
}


.my-swiper {
  width: 90%;
  padding: 0 2% 3%;
  min-height: 499px;
  // margin-top: 30px;



  .my-swiper-button-prev,
  .my-swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 55%);
    // width: calc(var(--swiper-navigation-size) / 44 * 27);
    // height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color, var(--swiper-theme-color));
  }

}





.info-group {
  padding: 60px;
  background-color: rgba(242, 241, 239, 1);
  height: calc(100vh - 120px);
  min-height: 692px;
  width: calc(100% - 120px);
  display: flex;
  align-items: center;

  .info-wrap {
    width: 100%;

    .info-head {
      width: 90%;
      margin: 0 auto;
      height: 70px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      // margin-bottom: 30px;
      transition: transform 1s ease;

      &.animate {
        transform: translateY(-50px);
      }
    }

    .head-wrap {
      position: relative;
      height: 70px;
    }

    .head-txt span {
      color: rgba(35, 35, 35, 1);
      font-size: 36px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      line-height: 50px;
      position: relative;
      z-index: 9;
    }

    .head-img {
      width: 337px;
      height: 47px;
      position: absolute;
      top: 26px;
      right: -86px;
      z-index: 1;
    }

    .btn-wrap {
      width: 200px;
      height: 48px;
      border-radius: 4px;
      border: 2px solid rgba(35, 35, 35, 0.85);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      color: #232323;
      transition: background-color 0.5s ease;
    }

    .btn-wrap:hover {
      background-color: #DEB055;
      border: 2px solid #DEB055;
      color: #FFFFFF;
    }

    .btn-wrap:active {
      background-color: #D49415;
      border: 2px solid #D49415;
      color: #FFFFFF;
    }

    .info-btn-txt {
      font-size: 20px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      line-height: 28px;
    }

    .info-btn-img {
      font-size: 12px !important;
      transform: scale(0.6);
    }

    .main-contact {
      background-color: #CCC;
      border-radius: 8px;
      transition: box-shadow 0.3s ease;

      position: relative;
      width: 25vw;
      height: 35vw;
      margin: 0 auto;
      overflow: hidden;

    }

    .main-contact:hover {
      // box-shadow: 0rem 2.5rem 5rem 0rem #B6B1A5;
      box-shadow: 0px 13px 26px #B6B1A5;
      // box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    }

    .main-img {
      // width: 100%;
      // height: auto;
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // border-radius: 0.67rem;
      // transform: translate(-50%, -50%);

      position: absolute;
      width: 100%;
      /* 将图片宽度设置为100% */
      height: 100%;
      /* 将图片高度设置为100% */
      object-fit: cover;
      /* 使图片等比例缩放并填充父元素 */

    }

    .contact-bg {
      background-color: rgba(35, 35, 35, 0.3);
      height: 520px;
      width: 100%;
      position: relative;
      border-radius: 8px;
    }

    .contact-desc {
      width: calc(100% - 24px);
      background-color: rgba(35, 35, 35, 0.60);
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      padding: 18px 12px;
      border-radius: 0 0 8px 8px;
      backdrop-filter: blur(6px)
    }

    .contact-desc::after {
      content: "";
      width: calc(100% - 24px);
      position: absolute;
      left: 0;
      bottom: 0;
      background: inherit;
      filter: blur(2px);
      z-index: 2;
    }



    .desc-date {
      background-color: rgba(216, 216, 216, 0);
      width: 40px;
      height: 55px;
    }

    .date-year {
      width: 34px;
      height: 20px;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 0.65);
      font-size: 14px;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      white-space: nowrap;
      line-height: 20px;
      margin: 2px 0 0 3px;
    }

    .desc-date-wrap {
      width: 38px;
      height: 32px;
      margin-bottom: 1px;
    }

    .split-icon {
      width: 22px;
      height: 11px;
      margin-left: 9px;
    }

    .date-month {
      width: 38px;
      height: 20px;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 0.65);
      font-size: 14px;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      white-space: nowrap;
      line-height: 20px;
      margin-top: 1px;
    }

    .split-line {
      background-color: rgba(242, 241, 239, 0.65);
      width: 1px;
      height: 50px;
      margin: 6px 17px;
    }

    .info-blurb {
      width: 260px;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 18px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      line-height: 24px;
    }


  }

  .swiper-btn {
    cursor: pointer;
  }
}


.join-group {
  position: relative;
  height: 100vh;
  min-height: 700px;

  .video-player {
    width: 100%;
    height: 100vh;
    min-height: 700px;
    object-fit: cover;
    position: absolute;
    z-index: 1;
  }

  .join-mask {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100vh;
    min-height: 700px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .join-contact {
    position: absolute;
    z-index: 10;
    width: 100%;
    padding: 60px;
    bottom: 100px;

    .join-btn-icon {
      color: #fff;
      font-size: 12px;
      transform: scale(0.6);
    }
  }
}
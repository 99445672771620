.news-page {
  background-color: #F2F1EF;
}

.news-menu-group {
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
}

li {
  list-style-type: none;
}

.tabs {
  width: 100%;
  padding-top: 64px;
}

.tab-group {
  background-color: #FFF;
  margin-bottom: 32px;
}

.tab-list {
  height: 64px;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  background-color: #FFF;
}

/* 设置列表的样式 */
.tab-list li {
  height: 61px;
  line-height: 64px;
  margin-right: 40px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  color: #898989;
}

.tab-list .current {
  color: #D8A237;
}

.tab-con {
  min-height: 100vh;
  width: 80%;
  max-width: 1200px;
  position: relative;
  top: 50px;
  margin: 0 auto;
  transition: transform 1s ease;
  animation-delay: 2s;
}

.tab-con.move-up {
  transform: translateY(-50px);
}

.current {
  border-bottom: 2px solid #D8A237;
  color: #D8A237;
}

.item {
  max-width: 1200px;
  height: 200px;
  margin: 20px 0;
  padding: 20px;
  background-color: #fdfdfd;
  border: 0.75px solid #fff;
  border-radius: 8px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  transition: box-shadow 0.3s ease;
}

.item:hover {
  box-shadow: 0px 4px 20px 0px rgba(226, 219, 206, 1);
}

.news-date-wrap {
  display: flex;

  .news-date {
    border-radius: 8px 0px 0px 8px;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .news-year {
      overflow-wrap: break-word;
      color: rgba(137, 137, 137, 1);
      font-size: 24px;
      font-family: PingFangSC-Regular;
      text-align: left;
      white-space: nowrap;
      line-height: 33px;
    }

    .news-slice {
      width: 28px;
      height: 17px;
      margin: 3px auto;
    }

    .news-time {
      width: 63px;
      height: 33px;
      overflow-wrap: break-word;
      color: rgba(137, 137, 137, 1);
      font-size: 24px;
      font-family: PingFangSC-Regular;
      font-weight: NaN;
      text-align: left;
      white-space: nowrap;
      line-height: 33px;
    }
  }
}

.news-line {
  width: 1px;
  height: 90px;
}

.news-section {
  width: 59%;
}

.news-title {
  color: rgba(35, 35, 35, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
}

.news-blurb {
  margin-top: 16px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #777777;
  line-height: 17px;
}

.news-btn-select {
  width: 150px;
  height: 40px;
  border-radius: 4px;
  opacity: 0.85;
  border: 1px solid #232323;
  color: #232323;
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.news-btn-select:hover {
  border: 1px solid #DEB055;
  background-color: #DEB055;
  color: #FFF;
}

.news-btn-select:active {
  border: 1px solid #D49415;
  background-color: #D49415;
  color: #FFF;
}

.news-btn-txt {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: PingFangSC-Medium, PingFang SC;
}

.news-btn-icon {
  font-size: 12px !important;
  transform: scale(0.5);
}

.news-image {
  border-radius: 4px;
  width: 150px;
}

.paging-wrap {
  display: flex;
  justify-content: flex-end;
  margin: 60px 0 120px;
}

.ant-pagination {

  .ant-pagination-next,
  .ant-pagination-prev {
    .ant-pagination-item-link {
      border: 0.08rem solid #D9D9D9;
      color: #242526;
    }
  }

  .ant-pagination-item {
    border-radius: 0.17rem;
    border: 0.08rem solid #D9D9D9;
    color: #565656;
  }

  .ant-pagination-item-active.ant-pagination-item {
    border: 0.08rem solid #D9A336;
    background-color: #D9A336;

    a {
      color: #fff;
    }
  }
}
.about-group {
  width: 100%;
  height: 100vh;
  min-height: 690px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .video-wrap1 {
    width: 100%;
    height: 100vh;
    min-height: 690px;
    object-fit: cover;
    position: absolute;
    z-index: 1;
  }

  // background-image: url(~@/assets/aboutSniff.png);
  // background-repeat:no-repeat;
  // background-attachment: scroll;
  // background-size:cover;
  .cover-img {
    width: 100%;
    height: 100vh;
    min-height: 690px;
  }

  .screen-box1 {
    width: 100%;
    height: 100vh;
    min-height: 690px;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    z-index: 10;

    .about-wrap {
      height: 100vh;

      .play-btn {
        width: 6rem;
        border-radius: 50%;
        margin: 2.67rem 0;
        cursor: pointer;
      }

      .fade-in {
        opacity: 0;
        transition: opacity 0.5s ease-in;
      }

      .fade-in:hover {
        opacity: 1;
      }

      .fade-out {
        opacity: 1;
        transition: opacity 0.5s ease-out;
      }

      .fade-out:hover {
        opacity: 0;
      }
    }

    .slide-browse {
      position: absolute;
      bottom: 30px;
      left: calc(50% - 58px);
      width: 116px;
      height: 60px;
      display: flex;
      flex-flow: column;
      align-items: center;
    }

    .slide-icon {
      font-size: 12px;
      color: #fff;
      transform: scale(0.8);
      text-align: center;
      cursor: pointer;
      animation: fade 2s infinite;
      animation-delay: -1s;
      display: block;
      margin-bottom: -5px;
    }

    .slide-icon1 {
      font-size: 12px;
      color: #fff;
      transform: scale(0.8);
      text-align: center;
      cursor: pointer;
      animation: fade 2s infinite;
    }

    @keyframes fade {
      0% {
        color: rgba(255, 255, 255, 1);
      }

      30% {
        color: rgba(255, 255, 255, 0.8);
      }

      60% {
        color: rgba(255, 255, 255, 0.5);
      }

      80% {
        color: rgba(255, 255, 255, 0.8);
      }

      100% {
        color: rgba(255, 255, 255, 1);
      }
    }

    .browse-more {
      height: 1.34rem;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 1.34rem;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      white-space: nowrap;
      line-height: 17px;
      margin-top: 8px;
    }
  }
}

.about-wrap,
.about-block {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.about-title {
  position: relative;

  span {
    color: rgba(255, 255, 255, 1);
    font-size: 46px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    line-height: 65px;
  }

  .about-adorn {
    position: absolute;
    z-index: -1;
    right: -30px;
    top: -18px;
    width: 260px;
  }
}



.about-txt {
  width: 600px;
  height: 44px;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  line-height: 22px;
  margin-top: 8px;
}



.slide-group {
  width: 100%;
  padding-top: 80vh;
}


.business-group {
  width: calc(100% - 120px);
  min-height: 680px;
  height: 100vh;
  background: url(~@/assets/zixun-bg.jpg) 100% no-repeat;
  background-size: 100% 100%;
  padding: 0 60px;
  display: flex;
  align-items: center;
  position: relative;
}

.business-wrap {
  width: 100%;
}



.consultation-title {
  position: relative;
  color: rgba(35, 35, 35, 1);
  font-size: 36px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  line-height: 50px;
  text-align: center;
  margin: 0;
  transition: transform 1s ease;

  &.animate {
    transform: translateY(-20px);
  }

  span {
    display: block;
    position: inherit;
    z-index: 9;
  }

  .business-adorn {
    position: absolute;
    top: 68px;
    right: calc(50% - 186px);
    width: 325px;
    z-index: 1;
    transform: translate(0, -100%);
  }
}

.consultation-wrap {
  width: 100%;
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
}

.phone-section {
  background: url(~@/assets/phone_bg.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 360px;
  height: 520px;
  position: relative;
}

.contact-section {
  width: 360px;
  height: 300px;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 0;
}

.phone-main {
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mini-icon {
  font-size: 20px;
  color: rgba(144, 129, 100, 1);
  margin-top: 1px;
}

.phone-txt {
  height: 22px;
  margin-left: 8px;
  overflow-wrap: break-word;
  color: rgba(144, 129, 100, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}

.phone-num {
  color: rgba(216, 162, 55, 1);
  font-size: 24px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 33px;
  margin: 8px auto;
}

.phone-split {
  background-color: rgba(237, 233, 223, 1);
  border-radius: 2px;
  width: 256px;
  height: 1px;
  margin-bottom: 16px;
}

.time-text {
  height: 16px;
  overflow-wrap: break-word;
  color: rgba(86, 86, 86, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  text-align: left;
  line-height: 16px;
  margin-bottom: 12px;
}

.email-section {
  width: 360px;
  height: 520px;
  background: url(~@/assets/email_bg.png) 100% no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.email-top {
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.email-icon {
  font-size: 20px;
  color: rgba(85, 115, 162, 1);
  margin-top: 1px;
}

.email-txt {
  height: 22px;
  margin-left: 8px;
  overflow-wrap: break-word;
  color: rgba(85, 115, 162, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}

.email-url {
  width: 198px;
  height: 33px;
  overflow-wrap: break-word;
  color: rgba(66, 149, 243, 1);
  font-size: 24px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 33px;
  margin: 8px auto;
}

.email-split {
  width: 256px;
  height: 1px;
  background-color: rgb(204, 232, 243);
  margin-bottom: 16px;
}

.work-contact-btn {
  background-color: #000;
  border-radius: 4px;
  height: 40px;
  width: 120px;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.work-contact-btn:hover {
  background-color: #DEB055;
}

.work-contact-btn:active {
  background-color: #D49415;
}

.line-section {
  width: 360px;
  height: 520px;
  background: url(~@/assets/line_bg.png) 100% no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.line-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.line-icon {
  font-size: 20px;
  color: rgba(119, 158, 100, 1);
  margin-top: 1px;
}


.line-text {
  height: 22px;
  margin-left: 8px;
  overflow-wrap: break-word;
  color: rgba(119, 158, 100, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}

.line-title {
  height: 33px;
  color: rgba(68, 197, 5, 1);
  font-size: 24px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  line-height: 33px;
  margin: 8px auto;
}

.line-split {
  background-color: rgba(233, 244, 226, 1);
  border-radius: 2px;
  width: 256px;
  height: 1px;
  margin-bottom: 16px;
}

.work-btn-txt {
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}
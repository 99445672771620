.corp-group {
  width: 100%;
  height: 100vh;
  min-height: 692px;
  position: relative;
  background: url(../../assets/Sketch.png) 100% no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;

  .corp-wrap {
    width: 94%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .aboutSwiper {
    position: relative;

    .swiper-pagination {
      width: auto;
      position: absolute;
      bottom: 0;
      left: 4vw;
    }
  }

  .topic-title {
    width: 92%;
    height: 50px;
    margin: 0 auto;
    transition: transform 1s ease;
    position: relative;
    top: 5vw;
    z-index: 9;

    span {
      font-size: 36px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      line-height: 50px;
      position: absolute;
      z-index: 9;
    }

    .adorn-img {
      position: absolute;
      left: 1%;
      top: 0;
      width: 230px;
    }
  }

  .topic-title.move-up {
    transform: translateY(-50px);
  }



  .contact-btn {
    width: 25rem;
    height: 5rem;
    background-color: #D8A237;
    border-radius: 0.33rem;
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
  }

  .contact-btn:hover {
    background-color: #DEB055;
  }

  .contact-btn:active {
    background-color: #D49415;
  }

  .contact-txt {
    text-align: left;
    font-size: 2rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 2.75rem;
  }

  .swiper-pagination-bullet {
    width: 5rem;
    height: 0.33rem;
    background: #635d4f;
    border-radius: 0;
    margin: 0;
  }

  .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 !important;
  }

  .swiper-pagination-bullet-active {
    background: #D8A237;
  }

  .topic {
    text-align: left;
    height: 155px;
    margin-top: 10px;
  }

  .swiper-slide {
    position: relative;

    .row-left {
      position: absolute;
      width: 55vw;
      left: 4vw;
      z-index: 10;
    }

    .row-right {
      position: absolute;
      width: 50rem;
      right: 4vw;
      z-index: 9;
    }
  }

  .data-group {
    max-width: 15vw;
    display: flex;
    flex-direction: column;
    margin-right: 40px;
  }

  .data-title {
    margin-bottom: 3px;
    min-height: 3.67rem;
    font-size: 1.33rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #565656;
    line-height: 1.83rem;
  }

  .data-num {
    height: 3.75rem;
    font-size: 2.67rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #D8A237;
    line-height: 3.75rem;

  }

  .img-wrap {
    width: 100%;
  }

  .topic-img {
    margin-left: 2.5rem;
    width: 50rem;
    height: 50rem;
  }

  .topic-icon {
    position: absolute;
    width: 7.15rem;
    top: 12rem;
    right: 10rem;
  }

  .topic-icon1 {
    position: absolute;
    width: 7.15rem;
    top: 8rem;
    right: 22rem;
  }

  .topic-icon2 {
    width: 7.15rem;
    position: absolute;
    top: 17rem;
    right: 6rem;
  }

  .topic-icon3 {
    position: absolute;
    width: 7.15rem;
    top: 8rem;
    right: 13rem;
  }

  .topic-icon4 {
    position: absolute;
    width: 7.15rem;
    top: 10rem;
    right: 7rem;
  }

  .topic-icon5 {
    position: absolute;
    width: 7.15rem;
    top: 17rem;
    right: 16rem;
  }

  .topic-num {
    overflow-wrap: break-word;
    color: rgba(227, 223, 213, 1);
    font-size: 140px;
    text-transform: uppercase;
    font-family: TimesNewRomanPS-BoldMT;
    text-align: left;
    white-space: nowrap;
    line-height: 161px;
  }

  .topic-name {
    color: rgba(35, 35, 35, 1);
    font-size: 24px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    line-height: 33px;
    margin: 92px 0 0 -60px;
  }

  .topic-txt {
    font-size: 1.17rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #565656;
    line-height: 1.85rem;
    letter-spacing: 0.06rem;
    text-align: justify;
    // text-align-last: justify;
    // text-justify: distribute-all-lines;
    // word-break: break-all;
  }



  .contact-icon {
    color: rgba(255, 255, 255, 1);
    font-size: 12px !important;
    transform: scale(0.6);
  }

  .swiper {
    width: 100%;
  }



  .swiper-slide {
    text-align: left;
    font-size: 18px;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    content: none;
  }

  .swiper-btn {
    width: 40px;
  }

}
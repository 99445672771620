.office-page {
  background-color: rgba(242, 241, 239, 1);
  position: relative;
}

.office-menu-group {
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
}

.office-wrapper {
  min-height: 100vh;
  width: 1000px;
  margin: 0 auto;
  padding: 120px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.office-title {
  color: rgba(35, 35, 35, 1);
  font-size: 36px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  line-height: 50px;
  margin: 60px auto;
  transition: transform 1s ease;

  &.animate {
    transform: translateY(-50px);
  }
}

.office-paragraph {
  color: rgba(86, 86, 86, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  margin-bottom: 20px;
}

.office-text-wrap {
  margin-bottom: 60px;
}

.office-group {
  width: 100%;
  position: relative;

  .swiper-button-next:after,
  .swiper-button-prev:after {
    content: none;
  }
}

.left-label {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 0;
}

.block_1 {
  // width: 90%;
  height: auto;
  border-radius: 0.5rem;

}

.right-label {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  right: 0;
}
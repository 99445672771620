.page {
  background-color: rgba(249, 249, 249, 1);
  position: relative;
  width: 100%;
  overflow: hidden;
}

.menu-a {
  color: #ffffff;
}

.menu-txt {
  color: #565656;
}

.menu-txt:active {
  color: #d8a237;
}

a {
  text-decoration: none;
}

// .wap-lang-container1 {
//   border-radius: 4px;
//   position: relative;
//   width: 100px;
//   height: 32px;
//   margin: 12px 40px;
//   border: 1.2px solid #565656;
// }

// .wap-lang-container {
//   border-radius: 4px;
//   position: relative;
//   width: 100px;
//   height: 32px;
//   margin: 12px 40px;
//   border: 1.2px solid rgba(255, 255, 255, 0.85);
// }

.select-wrap {
  width: 100px;
  height: 32px;
  border: 0;
  inline-size: none;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0);
  color: #ffffff;
}

.select-wrap1 {
  width: 100px;
  height: 32px;
  border: 0;
  inline-size: none;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0);
  color: #565656;
}

option {
  color: #ffffff;
  line-height: 20px;
}

.row-wrap {
  display: flex;
  flex-flow: row nowrap;
}

.topic-img {
  width: 100%;
}

.topic {
  height: 155px;
  margin-top: 10px;
}

.topic-name {
  color: rgba(35, 35, 35, 1);
  font-size: 24px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  line-height: 33px;
  margin: 92px 0 0 -60px;
}

.data-wrap {
  margin-top: 16px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.join-title {
  margin-bottom: 30px;
  display: flex;
  flex-flow: column;
  position: relative;
}

.join-slogan span {
  color: rgba(255, 255, 255, 1);
  font-size: 46px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  line-height: 65px;
  position: relative;
  z-index: 100;
}

.join-adorn {
  width: 173px;
  height: 64px;
  position: absolute;
  bottom: 40px;
  left: 28px;
}

.join-txt {
  width: 227px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  line-height: 28px;
  margin-top: 8px;
}

.join-btn {
  background-color: #d8a237;
  border-radius: 4px;
  height: 60px;
  width: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.join-btn:hover {
  background-color: #deb055;
}

.join-btn:active {
  background-color: #d49415;
}

.join-btn-txt {
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  line-height: 60px;
}

.menu-group {
  background-color: rgba(234, 232, 228, 1);
  width: calc(100% - 120px);
  padding: 60px 60px 20px;
}

.menu-wrap {
  display: flex;
  justify-content: space-between;
}

.menu-logo {
  // width: 180px;
  height: 63px;
}

.menu-list {
  width: 45%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.menu-head {
  color: rgba(86, 86, 86, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  white-space: nowrap;
  line-height: 22px;
}

.group-wrap {
  margin-top: 30px;
  display: flex;
  flex-flow: column;
}

.menu-text {
  margin-bottom: 6px;
}

.menu-text a {
  height: 20px;
  overflow-wrap: break-word;
  color: #565656;
  font-size: 14px;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 10px;
  transition: color 0.3s ease;
}

.menu-text a:hover {
  color: #deb055;
}

.menu-text a:active {
  color: #d49415;
}

.menu-split {
  background-color: rgba(222, 220, 216, 1);
  width: 100%;
  height: 1px;
  margin-top: 40px;
}

.bottom-contact {
  width: 100%;
  height: 25px;
  margin: 3.5rem auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.right {
  font-size: 0;
  font-family: LucidaGrande;
  text-align: left;
  display: flex;
  align-items: center;
}

.right-icon {
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(86, 86, 86, 1);
  font-size: 14px;
  font-family: LucidaGrande;
  text-align: left;
  white-space: nowrap;
}

.right-txt {
  overflow-wrap: break-word;
  color: rgba(86, 86, 86, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 17px;

  a {
    color: rgba(86, 86, 86, 1);
  }
}

.contact-way {
  display: flex;
  align-items: center;
}

.contact-us {
  overflow-wrap: break-word;
  color: rgba(86, 86, 86, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-right: 20px;
}

.way-wrap {
  width: 166px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.way-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  color: #565656;
  cursor: pointer;
  transition: color 0.3s ease;
}

.way-icon:hover {
  color: #deb055;
}

.way-icon:active {
  color: #d49415;
}

.swiper-btn {
  width: 40px;
}

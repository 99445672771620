.header-menu {
  transition: transform 0.2s ease-in;

  .menu-item {
    display: inline;
    margin-left: 40px;
    font-weight: 500;
    line-height: 64px;
  }

  &.header-menu-down-show {
    transform: translateY(0px);
  }

  &.header-menu-up-close {
    transform: translateY(-64px);
  }

  .logo-img {
    // width: 114px;
    height: 40px;
    margin-top: 12px;
    cursor: pointer;
  }

  .home-menu {
    display: flex;
    margin-left: 40px;
  }

  .top-menu-wrap {
    list-style: none;
    padding: 0;
  }

  .menu-item {
    display: inline;
    margin-left: 40px;
    font-size: 1.17rem;
    font-weight: 500;
    line-height: 64px;
  }
}

.lang-select-popup.sniff-nav-dark {
  background: linear-gradient(135deg, rgba(86, 86, 86, 0.5) 0%, rgba(86, 86, 86, 0.2) 100%);

  .ant-select-item.ant-select-item-option {
    color: #ffffff !important;

    &.ant-select-item-option-active {
      color: #ffffff !important;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.45) 0%, rgba(255, 255, 255, 0) 100%) !important;
    }

    &.ant-select-item-option-selected {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.45) 0%, rgba(255, 255, 255, 0) 100%) !important;
    }
  }
}

.header-menu.sniff-nav-dark {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  position: fixed;
  z-index: 1000;

  .logo-img.light {
    display: none;
  }

  .logo-img.dark {
    display: block;
  }

  .lang-change-wrap {
    margin-right: 40px;
    border: 1.2px solid #fff;
    border-radius: 4px;
    padding: 0 2px 0 10px;
    height: 32px;

    .lang-change-icon {
      color: #fff;
    }

    .arrow-icon {
      color: #fff !important;
    }

    .lang-select {
      width: 100px;

      .ant-select-selection-item {
        // padding-left: 20px;
        color: #fff;
      }

      .ant-select-selector {
        border: 0;
        background-color: transparent;
        box-shadow: none !important;
        padding: 0 0 0 8px !important;

        .ant-select-selection-item {
          padding-right: 10px;
        }
      }
    }
  }

  .menu-a.active,
  .menu-a:active {
    padding-bottom: 6px;
    border-bottom: 1px solid #ffffff;
  }
}

.header-menu.sniff-nav-light {
  background-color: #fff;
  // background: linear-gradient(90deg, rgba(255,255,255,0.85) 0%, rgba(255,255,255,0.85) 100%);
  background-image: none;
  position: fixed;
  z-index: 1000;

  .logo-img.light {
    display: block;
  }

  .logo-img.dark {
    display: none;
  }

  .lang-select-popup {
    .ant-select-item.ant-select-item-option {
      color: #565656;

      // background: linear-gradient(90deg, rgba(255,255,255,0.45) 0%, rgba(255,255,255,0) 100%);
      &.ant-select-item-option-active {
        color: #d8a237;
        background: linear-gradient(90deg, rgba(219, 207, 169, 0.45) 0%, rgba(219, 207, 169, 0) 100%);
      }

      &.ant-select-item-option-selected {
      }
    }
  }

  .lang-change-wrap {
    margin-right: 40px;
    border: 1.2px solid #232323;
    border-radius: 4px;
    padding: 0 2px 0 10px;
    height: 32px;

    .lang-change-icon {
      color: #232323;
    }

    .arrow-icon {
      color: #232323 !important;
    }

    .lang-select {
      width: 100px;

      .ant-select-selection-item {
        // padding-left: 20px;
      }

      .ant-select-selector {
        border: 0;
        background-color: transparent;
        box-shadow: none !important;
        padding: 0 0 0 8px !important;

        .ant-select-selection-item {
          padding-right: 10px;
        }
      }
    }

    .lang-change-icon {
      color: #232323;
    }
  }

  .menu-a {
    color: #565656;
  }

  .menu-a.active,
  .menu-a:active {
    color: #d8a237;
    padding-bottom: 6px;
    border-bottom: 1px solid #d8a237;
  }
}

.lang-change-wrap {
  margin-right: 40px;
  border: 1px solid #232323;
  border-radius: 4px;
  padding: 0 2px 0 10px;
  height: 32px;
  display: flex;
  align-items: center;

  .ant-select-dropdown {
    font-size: 1.34rem;
  }

  .lang-select {
    .ant-select-selector {
      border: 0;
      font-size: 1.34rem;
      background-color: transparent;
      box-shadow: none !important;
      padding: 0 0 0 8px !important;

      .ant-select-selection-item {
        padding-right: 10px;
      }
    }
  }

  .lang-change-icon {
    color: #232323;
  }
}

.lang-select-popup.ant-select-dropdown {
  border-radius: 0.67rem;
  padding: 0;
  right: 2rem !important;

  .ant-select-item.ant-select-item-option {
    color: #565656;

    // background: linear-gradient(90deg, rgba(255,255,255,0.45) 0%, rgba(255,255,255,0) 100%);
    &.ant-select-item-option-active {
      color: #d8a237;
      background: linear-gradient(90deg, rgba(219, 207, 169, 0.45) 0%, rgba(219, 207, 169, 0) 100%);
    }

    &.ant-select-item-option-selected {
    }
  }
}

.lang-change-wrap {
  .ant-select-show-arrow {
    &.ant-select-open {
      .ant-select-arrow {
        transform: rotate(180deg) !important;
      }
    }

    .ant-select-arrow {
      transform: rotate(0deg) !important;
      transition: transform 0.2s ease-in;
    }
  }
}

.home-group {
  width: 100%;
  position: fixed;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
}

.arrow-icon {
  font-size: 12px;
}

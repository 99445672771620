.page {
  background-color: rgba(242, 241, 239, 1);
  position: relative;
  width: 100%;
}

// 首屏
.joinUs-group {
  background: url(~@/assets/joinUsBg.jpg) 100% no-repeat;
  background-attachment: scroll;
  background-size: cover;
  background-size: 100% 100%;
  height: 100vh;
  width: 100%;
  position: relative;

  .cover-layer {
    background-color: rgba(35, 35, 35, 0.4);
    width: 100%;
    height: 100vh;

    .top-group {
      width: 100%;
      z-index: 100;
    }

    .goal-box {
      padding: 64px 0 40px;
      height: calc(100% - 104px);
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      position: relative;

      .ideal-txt {
        color: rgba(255, 255, 255, 1);
        font-size: 46px;
        font-family: PingFangSC-Medium;
        margin: 0;
        position: relative;

        span {
          position: relative;
          z-index: 100;
        }

        .ideal-adorn {
          width: 407px;
          height: 89px;
          position: absolute;
          top: -5px;
          right: -35px;
        }
      }

      .goal-txt {
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
        font-family: PingFangSC-Medium;
        line-height: 28px;
        margin-top: 8px;
      }

      .goal-btn {
        background-color: #D8A237;
        border-radius: 4px;
        padding: 19px 24px;
        margin-top: 30px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #DEB055;
        }

        &:active {
          background-color: #D49415;
        }

        .btn-txt {
          color: rgba(255, 255, 255, 1);
          font-size: 24px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
        }

        .join-btn-icon {
          color: #fff;
          font-size: 12px;
          transform: scale(0.6);
        }
      }
    }
  }

  .slide-browse {
    position: absolute;
    bottom: 30px;
    left: calc(50% - 58px);
    width: 116px;
    height: 60px;
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .slide-icon {
    font-size: 12px;
    color: #fff;
    transform: scale(0.8);
    text-align: center;
    cursor: pointer;
    animation: fade 2s infinite;
    animation-delay: -1s;
    display: block;
    margin-bottom: -5px;
  }

  .slide-icon1 {
    font-size: 12px;
    color: #fff;
    transform: scale(0.8);
    text-align: center;
    cursor: pointer;
    animation: fade 2s infinite;
  }

  @keyframes fade {
    0% {
      color: rgba(255, 255, 255, 1);
    }

    30% {
      color: rgba(255, 255, 255, 0.8);
    }

    60% {
      color: rgba(255, 255, 255, 0.5);
    }

    80% {
      color: rgba(255, 255, 255, 0.8);
    }

    100% {
      color: rgba(255, 255, 255, 1);
    }
  }

  .browse-more {
    height: 1.34rem;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 1.34rem;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 17px;
    margin-top: 8px;
  }
}

// 福利 
.welfare-one {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url(~@/assets/jiankangBg.png) 100% no-repeat;
  background-size: 100% 100%;

  .welfare-title {
    position: relative;
    margin: 0;
    padding-top: 8rem;
    transition: transform 1s ease;

    &.animate {
      transform: translateY(-50px);
    }

    .title-txt {
      color: rgba(35, 35, 35, 1);
      font-size: 36px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      text-align: center;
      display: block;
      position: inherit;
      z-index: 2;
    }

    .welfare-Adorn {
      width: 336px;
      height: 46px;
      position: absolute;
      bottom: -1rem;
      left: calc(50% - 122px);
      z-index: 0;
    }
  }

  .heart {
    width: 80%;
    margin: 0 auto;
    height: calc(100vh - 146px);
    display: flex;
    flex-flow: row nowrap;

    .heart-section {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;


      .left {
        width: 49%;
        transition: transform 1s ease;

        &.animate {
          transform: translateY(-30px);
        }

        .keyword {
          height: 50px;
          color: rgba(35, 35, 35, 1);
          font-size: 36px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          line-height: 50px;
          position: relative;

          span {
            position: relative;
            z-index: 100;
          }

          .adorn {
            position: absolute;
            width: 139px;
            left: -13px;
            top: -32px;
          }
        }

        .content {
          height: 137px;
          margin-top: 32px;
          text-align: justify;

          .brief {
            margin-bottom: 1.33rem;
            overflow-wrap: break-word;
            color: rgba(35, 35, 35, 1);
            font-size: 24px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            text-align: left;
            line-height: 33px;
          }

          .details {
            width: 488px;
            overflow-wrap: break-word;
            color: rgba(86, 86, 86, 1);
            font-size: 16px;
            font-family: PingFangSC-Regular;
            font-weight: NaN;
            text-align: left;
            line-height: 22px;
            margin-top: 16px;
            line-height: 1.85rem;
            letter-spacing: 0.06rem;
            text-align: justify;
          }
        }
      }

      .right {
        width: 49%;

        &.mask-animate {
          .mask-img {
            mask-size: 104vw;
          }
        }

        .mask-img {
          mask-image: url(~@/assets/S.svg);
          mask-size: 29vw;
          mask-repeat: no-repeat;
          mask-position: center;
          transition: mask-size 1s ease-in;
          transition-delay: 1s;

          .figure {
            width: 100%;
            border-radius: 0.5rem;
          }
        }
      }

      .jump-words {
        width: 511px;
        height: 383px;
        position: absolute;
        right: 0;
        bottom: 2rem;

        .aixin {
          position: absolute;
          width: 12rem;
        }

        .aixin:nth-child(1) {
          --animate-delay: 0.1s;
          --animate-duration: 800ms;
        }

        .aixin:nth-child(2) {
          --animate-delay: 0.8s;
          --animate-duration: 1000ms;
        }

        .aixin:nth-child(3) {
          --animate-delay: 1.2s;
          --animate-duration: 800ms;
        }

        .aixin:nth-child(4) {
          --animate-delay: 1.6s;
          --animate-duration: 1000ms;
        }
      }
    }
  }
}

.welfare-two {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url(~@/assets/xiaoxinBg.png) 100% no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-flow: row nowrap;

  .filial-piety-section {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .left {
      width: 49%;
      transition: transform 1s ease;

      &.animate {
        transform: translateY(-30px);
      }

      .keyword {
        height: 50px;
        color: rgba(35, 35, 35, 1);
        font-size: 36px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        line-height: 50px;
        position: relative;

        span {
          position: relative;
          z-index: 100;
        }

        .adorn {
          position: absolute;
          width: 139px;
          left: -13px;
          top: -32px;
        }
      }

      .content {
        height: 137px;
        margin-top: 32px;
        text-align: justify;

        .brief {
          margin-bottom: 1.33rem;
          overflow-wrap: break-word;
          color: rgba(35, 35, 35, 1);
          font-size: 24px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          line-height: 33px;
        }

        .details {
          width: 488px;
          overflow-wrap: break-word;
          color: rgba(86, 86, 86, 1);
          font-size: 16px;
          font-family: PingFangSC-Regular;
          font-weight: NaN;
          text-align: left;
          line-height: 22px;
          margin-top: 16px;
          line-height: 1.85rem;
          letter-spacing: 0.06rem;
          text-align: justify;
        }
      }
    }

    .right {
      width: 49%;

      .image-trigger-show {
        height: 1px;
        opacity: 0;
      }

      &.mask-animate {
        .mask-img {
          mask-size: 1340px;
        }
      }

      .mask-img {
        mask-image: url(~@/assets/N.svg);
        mask-size: 30vw;

        mask-repeat: no-repeat;
        mask-position: center;

        transition: mask-size 1s ease-in;
        transition-delay: 1s;

        .figure {
          width: 100%;
          border-radius: 0.5rem;
        }
      }
    }

    .jump-words {
      width: 511px;
      height: 383px;
      position: absolute;
      left: 10%;
      bottom: 10rem;

      .aixin {
        position: absolute;
        width: 12rem;
      }

      .aixin:nth-child(1) {
        --animate-delay: 0.1s;
        --animate-duration: 800ms;
      }

      .aixin:nth-child(2) {
        --animate-delay: 0.8s;
        --animate-duration: 1000ms;
      }

      .aixin:nth-child(3) {
        --animate-delay: 1.2s;
        --animate-duration: 800ms;
      }

      .aixin:nth-child(4) {
        --animate-delay: 1.6s;
        --animate-duration: 1000ms;
      }
    }
  }
}


.welfare-three {
  width: 100%;
  height: 100vh;
  background: url(~@/assets/jiankangBg.png) 100% no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;

  .health-section {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .left {
      width: 49%;
      transition: transform 1s ease;

      &.animate {
        transform: translateY(-30px);
      }

      .keyword {
        height: 50px;
        color: rgba(35, 35, 35, 1);
        font-size: 36px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        line-height: 50px;
        position: relative;

        span {
          position: relative;
          z-index: 100;
        }

        .adorn {
          position: absolute;
          width: 139px;
          left: -13px;
          top: -32px;
        }
      }

      .content {
        height: 137px;
        margin-top: 32px;
        text-align: justify;

        .brief {
          margin-bottom: 1.33rem;
          overflow-wrap: break-word;
          color: rgba(35, 35, 35, 1);
          font-size: 24px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          line-height: 33px;
        }

        .details {
          width: 488px;
          overflow-wrap: break-word;
          color: rgba(86, 86, 86, 1);
          font-size: 16px;
          font-family: PingFangSC-Regular;
          font-weight: NaN;
          text-align: left;
          line-height: 22px;
          margin-top: 16px;
          line-height: 1.85rem;
          letter-spacing: 0.06rem;
          text-align: justify;
        }
      }
    }

    .right {
      width: 49%;

      .image-trigger-show {
        height: 1px;
        opacity: 0;
      }

      &.mask-animate {
        .mask-img {
          mask-size: 178vw;
        }
      }

      .mask-img {
        mask-image: url(~@/assets/I.svg);
        mask-size: 35vw;

        mask-repeat: no-repeat;
        mask-position: center;

        transition: mask-size 1s ease-in;
        transition-delay: 1s;

        .figure {
          width: 100%;
          border-radius: 0.5rem;
        }
      }
    }

    .jump-words {
      width: 511px;
      height: 383px;
      position: absolute;
      right: 0;
      bottom: 2rem;

      .aixin {
        position: absolute;
        width: 12rem;
      }

      .aixin:nth-child(1) {
        --animate-delay: 0.1s;
        --animate-duration: 800ms;
      }

      .aixin:nth-child(2) {
        --animate-delay: 0.8s;
        --animate-duration: 1000ms;
      }

      .aixin:nth-child(3) {
        --animate-delay: 1.2s;
        --animate-duration: 800ms;
      }

      .aixin:nth-child(4) {
        --animate-delay: 1.6s;
        --animate-duration: 1000ms;
      }
    }
  }

}

.welfare-four {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url(~@/assets/xiaoxinBg.png) 100% no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-flow: row nowrap;

  .open-section {
    width: 80%;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .left {
      width: 49%;
      transition: transform 1s ease;

      &.animate {
        transform: translateY(-30px);
      }

      .keyword {
        height: 50px;
        color: rgba(35, 35, 35, 1);
        font-size: 36px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        line-height: 50px;
        position: relative;

        span {
          position: relative;
          z-index: 100;
        }

        .adorn {
          position: absolute;
          width: 139px;
          left: -13px;
          top: -32px;
        }
      }

      .content {
        height: 137px;
        margin-top: 32px;
        text-align: justify;

        .brief {
          margin-bottom: 1.33rem;
          overflow-wrap: break-word;
          color: rgba(35, 35, 35, 1);
          font-size: 24px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          line-height: 33px;
        }

        .details {
          width: 488px;
          overflow-wrap: break-word;
          color: rgba(86, 86, 86, 1);
          font-size: 16px;
          font-family: PingFangSC-Regular;
          font-weight: NaN;
          text-align: left;
          line-height: 22px;
          margin-top: 16px;
          line-height: 1.85rem;
          letter-spacing: 0.06rem;
          text-align: justify;
        }
      }
    }

    .right {
      width: 49%;

      .image-trigger-show {
        height: 1px;
        opacity: 0;
      }

      &.mask-animate {
        .mask-img {
          mask-size: 1340px;
        }
      }

      .mask-img {
        mask-image: url(~@/assets/F.svg);
        mask-size: 30vw;

        mask-repeat: no-repeat;
        mask-position: center;

        transition: mask-size 1s ease-in;
        transition-delay: 1s;

        .figure {
          width: 100%;
          border-radius: 0.5rem;
        }
      }
    }

    .jump-words {
      width: 511px;
      height: 383px;
      position: absolute;
      left: 0;
      bottom: 10rem;

      .aixin {
        position: absolute;
        width: 12rem;
      }

      .aixin:nth-child(1) {
        --animate-delay: 0.1s;
        --animate-duration: 800ms;
      }

      .aixin:nth-child(2) {
        --animate-delay: 0.8s;
        --animate-duration: 1000ms;
      }

      .aixin:nth-child(3) {
        --animate-delay: 1.2s;
        --animate-duration: 800ms;
      }

      .aixin:nth-child(4) {
        --animate-delay: 1.6s;
        --animate-duration: 1000ms;
      }
    }
  }
}

// .keyword {
//   height: 50px;
//   color: rgba(35, 35, 35, 1);
//   font-size: 36px;
//   font-family: PingFangSC-Medium;
//   font-weight: 500;
//   line-height: 50px;
//   position: relative;

//   span {
//     position: relative;
//     z-index: 100;
//   }

//   .adorn {
//     position: absolute;
//     width: 139px;
//     left: -13px;
//     top: -32px;
//   }
// }

// .content {
//   height: 137px;
//   margin-top: 32px;
//   text-align: justify;

//   .brief {
//     margin-bottom: 1.33rem;
//     overflow-wrap: break-word;
//     color: rgba(35, 35, 35, 1);
//     font-size: 24px;
//     font-family: PingFangSC-Medium;
//     font-weight: 500;
//     text-align: left;
//     line-height: 33px;
//   }

//   .details {
//     width: 488px;
//     overflow-wrap: break-word;
//     color: rgba(86, 86, 86, 1);
//     font-size: 16px;
//     font-family: PingFangSC-Regular;
//     font-weight: NaN;
//     text-align: left;
//     line-height: 22px;
//     margin-top: 16px;
//     line-height: 1.85rem;
//     letter-spacing: 0.06rem;
//     text-align: justify;
//   }
// }


// .jump-words {
//   width: 511px;
//   height: 383px;
//   position: absolute;
//   right: 0;
//   bottom: 0;

//   .aixin {
//     position: absolute;
//     width: 12rem;
//   }

//   .aixin:nth-child(1) {
//     --animate-delay: 0.1s;
//     --animate-duration: 800ms;
//   }

//   .aixin:nth-child(2) {
//     --animate-delay: 0.8s;
//     --animate-duration: 1000ms;
//   }

//   .aixin:nth-child(3) {
//     --animate-delay: 1.2s;
//     --animate-duration: 800ms;
//   }

//   .aixin:nth-child(4) {
//     --animate-delay: 1.6s;
//     --animate-duration: 1000ms;
//   }
// }

// .group_4 {
//   width: 49%;

//   .image-trigger-show {
//     height: 1px;
//     opacity: 0;
//   }

//   &.animate {
//     .mask-img {
//       mask-size: 178vw;
//     }
//   }

//   .mask-img {
//     mask-image: url(~@/assets/I.svg);
//     mask-size: 39vw;

//     mask-repeat: no-repeat;
//     mask-position: center;

//     transition: mask-size 1s ease-in;
//     transition-delay: 1s;

//     .image_9 {
//       width: 100%;
//       // height: 383px;
//       border-radius: 0.5rem;
//     }
//   }
// }



.office-city {
  background-color: rgba(242, 241, 239, 1);
  position: relative;
  // width: 80%;
  margin: 0 auto;

  .office-title {
    height: 50px;
    width: 100%;
    color: rgba(35, 35, 35, 1);
    font-size: 36px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    position: relative;
    z-index: 8;
    text-align: center;
    margin: 130px auto 10px;
    transition: transform 1s ease;

    &.animate {
      transform: translateY(-50px);
    }

    span {
      position: relative;
      z-index: 100;
    }

    .office-adorn {
      width: 339px;
      position: absolute;
      left: calc(50% - 130px);
      top: 22px;
    }

  }

  .city-legend {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.77vw;

    .list-items {
      width: 40.28vw;
      height: 30.21vw;
      border-radius: 8px;
      position: relative;
      overflow: hidden;
      transition: box-shadow 0.3s ease;

      &:hover {
        box-shadow: 0rem 2.5rem 5rem 0rem #B6B1A5;

        .background {
          transform: scale(1.05);
        }
      }

      .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        transition: transform 0.3s ease;
      }

      .city-1 {
        background-image: url(~@/assets/hangzhou.png);
      }

      .city-2 {
        background-image: url(~@/assets/dongjing.png);
      }

      .city-3 {
        background-image: url(~@/assets/dalian.png);
      }

      .city-4 {
        background-image: url(~@/assets/guangzhou.png);
      }

      .city-5 {
        background-image: url(~@/assets/xianggang.png);
      }

      .city-6 {
        background-image: url(~@/assets/hanguo.png);
      }

      .info-wrap {
        width: 100%;
        height: 11.11rem;
        border-radius: 0 0 8px 8px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: url(~@/assets/mask.png) 100% no-repeat;
        background-size: 100% 100%;
        box-shadow: 0 20px 40px 1px rgba(0, 0, 0, 0.12);
        backdrop-filter: blur(6px);

        &::after {
          content: "";
          width: 100%;
          height: 11.11rem;
          position: absolute;
          left: 0;
          bottom: 0;
          background: inherit;
          filter: blur(1px);
          z-index: 2;
        }

        .info-content {
          width: 100%;
          height: 5.48rem;
          box-sizing: border-box;
          padding: 16px 16px 5px;
          margin-bottom: 5px;
          display: flex;
          flex-flow: row;
          align-items: center;
          justify-content: space-between;
          position: relative;
          z-index: 10000;
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);

          .city-location {
            height: 42px;
            display: flex;
            flex-flow: row;
            justify-content: center;
            align-items: center;

            .city-icon {
              font-size: 32px;
              color: #fff;
              margin-right: 5px;
            }

            .city-name {
              font-size: 30px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 42px;
            }
          }

          .view-btn {
            height: 20px;
            color: #fff;
            font-size: 14px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            line-height: 20px;
            transition: color 0.3s ease;
          }

          .view-btn:hover {
            color: #DEB055;
          }

          .view-btn:active {
            color: #D49415;
          }

        }

        .deliver-btn {
          height: 35px;
          padding: 0 22px;
          border-radius: 4px;
          border: 1px solid rgba(255, 255, 255, 0.85);
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          align-items: center;
          z-index: 100;
          position: absolute;
          right: 10px;
          bottom: 1.3vw;
          color: #fff;
          transition: background-color 0.3s ease;

          &:hover {
            border: 1px solid #DEB055;
            background-color: #DEB055;
          }

          &:active {
            border: 1px solid #D49415;
            background-color: #D49415;
          }

          .btn-txt {
            font-size: 14px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            margin: 0 auto;
          }
        }

      }
    }
  }

}

* {
  margin: 0;
  padding: 0;
}

.join-sniff {
  min-height: 700px;
  margin: 8rem auto 0;

  .join-top {
    width: 100%;

    .join-title {
      margin: 112px auto -30px;
      color: rgba(35, 35, 35, 1);
      font-size: 36px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      line-height: 50px;
      text-align: center;
      position: relative;
      transition: transform 1s ease;

      &.animate {
        transform: translateY(-50px);
      }

      span {
        position: relative;
        z-index: 100;
      }

      img {
        width: 330px;
        height: 46px;
        position: absolute;
        top: 20px;
        left: calc(50% - 165px);
      }
    }

    p {
      width: 100%;
      display: flex;
      justify-content: center;
      color: rgba(86, 86, 86, 1);
      font-size: 1vw;
      font-family: PingFangSC-Regular;
      margin-bottom: 3.33rem;
    }
  }

  .join-us-tab {
    .ant-tabs {
      .ant-tabs-nav {
        &::before {
          border-bottom: 0.17rem solid #E2E1DC;
          width: 80%;
          margin: 0 auto;
        }

        .ant-tabs-ink-bar {
          height: 0.17rem;
        }

        .ant-tabs-tab {
          font-size: 1.1vw;
        }
      }
    }

    .mySwiper {
      width: 100%;

      .tab-item {
        width: 80%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        position: relative;

        .company-goal,
        .tab_1,
        .tab_2,
        .tab_3,
        .tab_4,
        .tab_5 {
          width: 50%;

          .goal-wrap {
            padding-left: 40px;

            .goal-title {
              font-size: 3rem;
            }

            .goal-content {
              display: inline-block;
              margin-top: 2.67rem;
              font-size: 2rem;
              width: 100%;
            }

            .goal-content-desc {
              display: inline-block;
              font-size: 1.17rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #898989;
              line-height: 1.83rem;
              margin-top: 0.6rem;
            }
          }
        }

        .company-goal {
          display: flex;
          align-items: center;
          justify-content: left;
        }

        .tab_1 {
          .mask-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 100%;
            mask-image: url(../../assets/1.svg);
            -webkit-mask-image: url(../../assets/1.svg);
            mask-size: 450px;
            mask-repeat: no-repeat;
            background-size: cover;
            mask-position: center;
            -webkit-mask-position: center;
            transition: mask-size 1s ease-in;

            .company-img {
              width: 100%;
              border-radius: 8px;
              height: auto;
              display: block;
            }
          }

          &.mask-animate {
            .mask-wrap {
              mask-size: 1340px;
            }
          }
        }

        .tab_2 {
          .mask-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 100%;
            mask-image: url(../../assets/1.svg);
            -webkit-mask-image: url(../../assets/1.svg);
            mask-size: 350px;
            mask-repeat: no-repeat;
            background-size: cover;
            mask-position: center;
            -webkit-mask-position: center;
            transition: mask-size 1s ease-in;

            .company-img {
              border-radius: 8px;
              width: 100%;
              height: auto;
              display: block;
            }
          }

          &.mask-animate {
            .mask-wrap {
              mask-size: 1340px;
            }
          }
        }

        .tab_3 {
          .mask-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 100%;
            mask-image: url(../../assets/2.svg);
            -webkit-mask-image: url(../../assets/2.svg);
            mask-size: 450px;
            mask-repeat: no-repeat;
            background-size: cover;
            mask-position: center;
            -webkit-mask-position: center;
            transition: mask-size 1s ease-in;

            .company-img {
              border-radius: 8px;
              width: 100%;
              height: auto;
              display: block;
            }
          }

          &.mask-animate {
            .mask-wrap {
              mask-size: 1340px;
            }
          }
        }

        .tab_4 {
          .mask-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 100%;
            mask-image: url(../../assets/3.svg);
            -webkit-mask-image: url(../../assets/3.svg);
            mask-size: 450px;
            mask-repeat: no-repeat;
            background-size: cover;
            mask-position: center;
            -webkit-mask-position: center;
            transition: mask-size 1s ease-in;

            .company-img {
              border-radius: 8px;
              width: 100%;
              height: auto;
              display: block;
            }
          }

          &.mask-animate {
            .mask-wrap {
              mask-size: 1340px;
            }
          }
        }

        .tab_5 {
          .mask-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 100%;
            mask-image: url(../../assets/4.svg);
            -webkit-mask-image: url(../../assets/4.svg);
            mask-size: 450px;
            mask-repeat: no-repeat;
            background-size: cover;
            mask-position: center;
            -webkit-mask-position: center;
            transition: mask-size 1s ease-in;

            .company-img {
              border-radius: 8px;
              width: 100%;
              height: auto;
              display: block;
            }
          }

          &.mask-animate {
            .mask-wrap {
              mask-size: 1340px;
            }
          }
        }

        .words {
          width: 50%;
          height: 100%;
          position: absolute;
          bottom: -1rem;
          right: 0;

          .aixin {
            width: 12rem;
            position: absolute;
          }

          .aixin:nth-child(1) {
            left: -2rem;
            bottom: 15rem;
            --animate-delay: 0.1s;
            --animate-duration: 800ms;
          }

          .aixin:nth-child(2) {
            left: 5rem;
            bottom: 5rem;
            --animate-delay: 0.8s;
            --animate-duration: 1000ms;
          }

          .aixin:nth-child(3) {
            left: 18rem;
            bottom: 3rem;
            --animate-delay: 1.2s;
            --animate-duration: 800ms;
          }

          .aixin:nth-child(4) {
            bottom: 7rem;
            right: 7rem;
            --animate-delay: 1.6s;
            --animate-duration: 1000ms;
          }

          .aixin:nth-child(5) {
            top: 16rem;
            right: -2rem;
            --animate-delay: 1.6s;
            --animate-duration: 1000ms;
          }
        }
      }
    }
  }
}
.news-page {
  background-color: #F2F1EF;
}

.news-menu-group {
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 100;
}

li {
  list-style-type: none;
}

.article-group {
  width: 1000px;
  overflow: hidden;
  margin: 64px auto 0;
  min-height: 100vh;
  background-color: #F2F1EF;
}

.article-title {
  padding-top: 10rem;
  text-align: center;
  width: 100%;
  // height: 4.17rem;
  font-size: 3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #232323;
  line-height: 4.17rem;
}

.article-date {
  height: 1.83rem;
  font-size: 1.33rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #898989;
  line-height: 1.83rem;
  text-align: center;
  margin: 1.33rem auto 5rem;
}

.article-pic {
  height: 400px;
  display: block;
  margin: 0 auto;
}

.article-entry {
  padding: 5rem 0;
}


.entry {
  height: 1.83rem;
  font-size: 1.33rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #232323;
  line-height: 1.83rem;
  margin-top: 1.33rem;
  cursor: pointer;
  display: block;
  transition: color 0.3s ease;
  /* 过渡效果 */
}

.entry:hover {
  color: #DEB055;
}

.entry:active {
  color: #D49415;
}

.article-content p,
.article-content p span {
  // color: rgba(137, 137, 137, 1) !important;
  // font-size: 16px !important;
  // font-family: PingFangSC-Regular;
  // line-height: 22px;
  // margin: 16px 0 40px;
  margin-bottom: 20px !important;
}

.article-content img {
  // max-width: 100% !important;
  // height: auto !important;
  // display: block;
  // margin: 40px auto;
  // border-radius: 8px;
  margin-bottom: 20px !important;
}
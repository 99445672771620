body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: 'iconfont';
  /* Project id 4227057 */
  src: url('//at.alicdn.com/t/c/font_4227057_ottj17qt11q.woff2?t=1694596089629') format('woff2'),
    url('//at.alicdn.com/t/c/font_4227057_ottj17qt11q.woff?t=1694596089629') format('woff'),
    url('//at.alicdn.com/t/c/font_4227057_ottj17qt11q.ttf?t=1694596089629') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

/* 1024px - 1223px */
@media (min-width: 1024px) and (max-width: 1223px) {
  html {
    font-size: 8px;
    /* 推荐字体大小 */
  }
}

/* 1224px - 1423px */
@media (min-width: 1224px) and (max-width: 1423px) {
  html {
    font-size: 10px;
    /* 推荐字体大小 */
    // font-size: 16px;
  }
}

/* 1424px - 1623px */
@media (min-width: 1424px) and (max-width: 1623px) {
  html {
    font-size: 12px;
    /* 推荐字体大小 */
  }
}

/* 1624px 及以上 */
@media (min-width: 1624px) {
  html {
    font-size: 14px;
    /* 推荐字体大小 */
  }
}